import firebase from 'firebase'

const config = {
  // apiKey: "AIzaSyDvinrdxdI0-Bm3c5gbak16Cwrdb6P6Dq4",
  // authDomain: "test-notif-834a0.firebaseapp.com",
  // databaseURL: "https://test-notif-834a0.firebaseio.com",
  // projectId: "test-notif-834a0",
  // storageBucket: "test-notif-834a0.appspot.com",
  // messagingSenderId: "1092728425634",
  // appId: "1:1092728425634:web:5fa5615bebad6a9ab7dbfb"

  // lama
  // apiKey: "AIzaSyBPtVcC9s2Zsrkw48Owt3C5CkHgaD0gyU8",
  // authDomain: "amila-community.firebaseapp.com",
  // projectId: "amila-community",
  // storageBucket: "amila-community.appspot.com",
  // messagingSenderId: "431961550826",
  // appId: "1:431961550826:web:2c07e6fd8b91be62300c93",
  // measurementId: "G-P39Q3V9NZV"
  // end lama

  // apiKey: "AIzaSyCFHqPHf1OGPxY7r4AzNW_JSJ64p_dJfy0",
  // authDomain: "kolonia.firebaseapp.com",
  // projectId: "kolonia",
  // storageBucket: "kolonia.appspot.com",
  // messagingSenderId: "490440028870",
  // appId: "1:490440028870:web:5c4bebef420d96d7441dc7"

  apiKey: "AIzaSyB2_e_-qfkE_dRK2PWwnQJZU70aU91lROM",
  authDomain: "otodidak-c5869.firebaseapp.com",
  projectId: "otodidak-c5869",
  storageBucket: "otodidak-c5869.appspot.com",
  messagingSenderId: "375067864650",
  appId: "1:375067864650:web:cfa4c39dc5e022a4ec40b3",
  measurementId: "G-BT42YX86BK"
}

if (!firebase.apps.length) { 
  firebase.initializeApp(config)
}

export default (ctx, inject) => {
  inject('firebase', firebase)
}