// export const strict = false

// export const state = () => {
//   return {
//     token: null,
//     user: {}
//   }
// }

// export const mutations = {
//   setToken (state, token) {
//     state.token = token
//   },
//   setUser (state, user) {
//     state.user = user
//   }
// }

export const cookieparser = process.server ? require("cookieparser") : undefined

export const strict = false

export const state = () => ({
  counter: 0
})

export const mutations = {
  increment(state) {
    state.counter++
  }
}

export const actions = {
  nuxtServerInit({ commit }, { req }) {
    let token = null, user = {}, fcm = null
    if (req.headers.cookie) {
      const parsed = cookieparser.parse(req.headers.cookie)
      try {
        token = parsed.token
        user = JSON.parse(parsed.user)
        fcm = parsed.fcm
      } catch (err) {
        // No valid cookie found
      }
    }
    commit('member/auth/setToken', token)
    commit('member/auth/setUser', user)
    commit('member/auth/setFcm', fcm)
  }
}
