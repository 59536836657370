const state = () => ({
  content: {}
})

const mutations = {
  setContent (state, content) {
    state.content = content
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}