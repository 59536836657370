import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _fc46bd1c = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _65e05a22 = () => interopDefault(import('../pages/account/admin-discount/index.vue' /* webpackChunkName: "pages/account/admin-discount/index" */))
const _24258977 = () => interopDefault(import('../pages/account/announcement/index.vue' /* webpackChunkName: "pages/account/announcement/index" */))
const _e1724964 = () => interopDefault(import('../pages/account/collection/index.vue' /* webpackChunkName: "pages/account/collection/index" */))
const _3898991e = () => interopDefault(import('../pages/account/discount/index.vue' /* webpackChunkName: "pages/account/discount/index" */))
const _03e58058 = () => interopDefault(import('../pages/account/discussion/index.vue' /* webpackChunkName: "pages/account/discussion/index" */))
const _8f93e802 = () => interopDefault(import('../pages/account/etalase/index.vue' /* webpackChunkName: "pages/account/etalase/index" */))
const _d906efca = () => interopDefault(import('../pages/account/notification/index.vue' /* webpackChunkName: "pages/account/notification/index" */))
const _27f90255 = () => interopDefault(import('../pages/account/product/index.vue' /* webpackChunkName: "pages/account/product/index" */))
const _28d13e0d = () => interopDefault(import('../pages/account/rating/index.vue' /* webpackChunkName: "pages/account/rating/index" */))
const _7d1ca721 = () => interopDefault(import('../pages/account/report.vue' /* webpackChunkName: "pages/account/report" */))
const _1b94be76 = () => interopDefault(import('../pages/account/setting/index.vue' /* webpackChunkName: "pages/account/setting/index" */))
const _ba39cb7e = () => interopDefault(import('../pages/account/transaction.vue' /* webpackChunkName: "pages/account/transaction" */))
const _6977e154 = () => interopDefault(import('../pages/account/voucher/index.vue' /* webpackChunkName: "pages/account/voucher/index" */))
const _7eac21a8 = () => interopDefault(import('../pages/account/voucher-wallet/index.vue' /* webpackChunkName: "pages/account/voucher-wallet/index" */))
const _eb4497de = () => interopDefault(import('../pages/account/product/form.vue' /* webpackChunkName: "pages/account/product/form" */))
const _7a4e92b4 = () => interopDefault(import('../pages/account/wishlist/elearning.vue' /* webpackChunkName: "pages/account/wishlist/elearning" */))
const _29a00b95 = () => interopDefault(import('../pages/account/wishlist/merchat.vue' /* webpackChunkName: "pages/account/wishlist/merchat" */))
const _226ee82f = () => interopDefault(import('../pages/account/announcement/_slug.vue' /* webpackChunkName: "pages/account/announcement/_slug" */))
const _022edf10 = () => interopDefault(import('../pages/account/discussion/_slug.vue' /* webpackChunkName: "pages/account/discussion/_slug" */))
const _93012a92 = () => interopDefault(import('../pages/account/etalase/_slug.vue' /* webpackChunkName: "pages/account/etalase/_slug" */))
const _271a9cc5 = () => interopDefault(import('../pages/account/rating/_slug.vue' /* webpackChunkName: "pages/account/rating/_slug" */))
const _6aaef1ab = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _bb3f8268 = () => interopDefault(import('../pages/category/index.vue' /* webpackChunkName: "pages/category/index" */))
const _685e37ea = () => interopDefault(import('../pages/elearning/index.vue' /* webpackChunkName: "pages/elearning/index" */))
const _4d5073ea = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _7aa2efee = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _16c54809 = () => interopDefault(import('../pages/loginOld.vue' /* webpackChunkName: "pages/loginOld" */))
const _e77a27f2 = () => interopDefault(import('../pages/organitation/index.vue' /* webpackChunkName: "pages/organitation/index" */))
const _38051e50 = () => interopDefault(import('../pages/preview/index.vue' /* webpackChunkName: "pages/preview/index" */))
const _8589f778 = () => interopDefault(import('../pages/publisher/index.vue' /* webpackChunkName: "pages/publisher/index" */))
const _2dbc47a4 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _53903048 = () => interopDefault(import('../pages/resetPasswordOld.vue' /* webpackChunkName: "pages/resetPasswordOld" */))
const _3a03c664 = () => interopDefault(import('../pages/collection/_slug.vue' /* webpackChunkName: "pages/collection/_slug" */))
const _6bcb7a7a = () => interopDefault(import('../pages/elearning/_slug.vue' /* webpackChunkName: "pages/elearning/_slug" */))
const _eae76a82 = () => interopDefault(import('../pages/organitation/_slug.vue' /* webpackChunkName: "pages/organitation/_slug" */))
const _88f73a08 = () => interopDefault(import('../pages/publisher/_slug.vue' /* webpackChunkName: "pages/publisher/_slug" */))
const _ec4a3578 = () => interopDefault(import('../pages/purchasing/_slug.vue' /* webpackChunkName: "pages/purchasing/_slug" */))
const _9e096652 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _fc46bd1c,
    name: "account",
    children: [{
      path: "admin-discount",
      component: _65e05a22,
      name: "account-admin-discount"
    }, {
      path: "announcement",
      component: _24258977,
      name: "account-announcement"
    }, {
      path: "collection",
      component: _e1724964,
      name: "account-collection"
    }, {
      path: "discount",
      component: _3898991e,
      name: "account-discount"
    }, {
      path: "discussion",
      component: _03e58058,
      name: "account-discussion"
    }, {
      path: "etalase",
      component: _8f93e802,
      name: "account-etalase"
    }, {
      path: "notification",
      component: _d906efca,
      name: "account-notification"
    }, {
      path: "product",
      component: _27f90255,
      name: "account-product"
    }, {
      path: "rating",
      component: _28d13e0d,
      name: "account-rating"
    }, {
      path: "report",
      component: _7d1ca721,
      name: "account-report"
    }, {
      path: "setting",
      component: _1b94be76,
      name: "account-setting"
    }, {
      path: "transaction",
      component: _ba39cb7e,
      name: "account-transaction"
    }, {
      path: "voucher",
      component: _6977e154,
      name: "account-voucher"
    }, {
      path: "voucher-wallet",
      component: _7eac21a8,
      name: "account-voucher-wallet"
    }, {
      path: "product/form",
      component: _eb4497de,
      name: "account-product-form"
    }, {
      path: "wishlist/elearning",
      component: _7a4e92b4,
      name: "account-wishlist-elearning"
    }, {
      path: "wishlist/merchat",
      component: _29a00b95,
      name: "account-wishlist-merchat"
    }, {
      path: "announcement/:slug",
      component: _226ee82f,
      name: "account-announcement-slug"
    }, {
      path: "discussion/:slug",
      component: _022edf10,
      name: "account-discussion-slug"
    }, {
      path: "etalase/:slug",
      component: _93012a92,
      name: "account-etalase-slug"
    }, {
      path: "rating/:slug",
      component: _271a9cc5,
      name: "account-rating-slug"
    }]
  }, {
    path: "/cart",
    component: _6aaef1ab,
    name: "cart"
  }, {
    path: "/category",
    component: _bb3f8268,
    name: "category"
  }, {
    path: "/elearning",
    component: _685e37ea,
    name: "elearning"
  }, {
    path: "/home",
    component: _4d5073ea,
    name: "home"
  }, {
    path: "/login",
    component: _7aa2efee,
    name: "login"
  }, {
    path: "/loginOld",
    component: _16c54809,
    name: "loginOld"
  }, {
    path: "/organitation",
    component: _e77a27f2,
    name: "organitation"
  }, {
    path: "/preview",
    component: _38051e50,
    name: "preview"
  }, {
    path: "/publisher",
    component: _8589f778,
    name: "publisher"
  }, {
    path: "/reset-password",
    component: _2dbc47a4,
    name: "reset-password"
  }, {
    path: "/resetPasswordOld",
    component: _53903048,
    name: "resetPasswordOld"
  }, {
    path: "/collection/:slug?",
    component: _3a03c664,
    name: "collection-slug"
  }, {
    path: "/elearning/:slug",
    component: _6bcb7a7a,
    name: "elearning-slug"
  }, {
    path: "/organitation/:slug",
    component: _eae76a82,
    name: "organitation-slug"
  }, {
    path: "/publisher/:slug",
    component: _88f73a08,
    name: "publisher-slug"
  }, {
    path: "/purchasing/:slug?",
    component: _ec4a3578,
    name: "purchasing-slug"
  }, {
    path: "/",
    component: _9e096652,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
