import Vue from 'vue'
import { required, email, max, min, min_value, max_value, confirmed } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'

extend('required', {
  ...required,
  message: '{_field_} tidak boleh kosong'
})

extend('tanggapan', {
  ...required,
  message: 'Tanggapan tidak boleh kosong'
})

extend('max', {
  ...max,
  message: '{_field_} maksimal {length} karakter'
})

extend('min', {
  ...min,
  message: '{_field_} minimal {length} karakter'
})

extend('min_0', {
  ...min_value,
  message: '{_field_} minimal 0'
})

extend('max_100', {
  ...max_value,
  message: '{_field_} maksimal 100'
})

extend('min_price', {
  ...min_value,
  message: '{_field_} minimal Rp 10.000'
})

extend('max_price', {
  ...max_value,
  message: '{_field_} maksimal Rp 1.000.000'
})

extend('email', {
  ...email,
  message: 'Email belum benar'
})

extend('confirmed', {
  ...confirmed,
  message: 'Konfirmasi password belum benar'
})

extend("max_price", {
  ...max_value,
  message: "{_field_} maksimal {max}"
});

extend('url', {
  validate(value) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return pattern.test(value);
  },
  message: 'format url belum benar.'
});

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  }
}